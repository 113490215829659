import moment from 'moment';
import { Text, Input, HStack, Flex, Select, Button, Highlight, Spinner, Avatar, Center, Image, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import LoadingScreen from '../components/LoadingScreen';
import { PushNotificationMessage, PushNotificationMessageData } from '../models/PushNotificationMessage';
import { sendBatchPushNotification } from '../services/AdminService';


function BatchNotificationsScreen() {
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<any>();
  const [user, loading, error] = useAuthState(auth);
  const [notificationModel, setNotificationModel] = useState<PushNotificationMessage>({ title: '', body: ''})
  const [notificationData, setNotificationData] = useState<PushNotificationMessageData | undefined>();

  const handleSend = async () => {
    if (!notificationModel.title) {onClose(); return;}
    if (!notificationModel.body) {onClose(); return;}
    if (notificationData) notificationModel.data = notificationData;
    await sendBatchPushNotification(user as UserAuth, notificationModel).then(() => {
        console.log('done!');
    })
    .catch((err) => console.log(err))
    .finally(() => onClose());
    onClose();
  }

  if (!user) return <LoadingScreen />;

  return (
    <Flex flexDirection="column" height="full" width="100%" flex={1} px={12} py={8} alignItems="center">
        <Text variant="header">Send Batch Notification</Text>
        <Text>Warning:  be very careful on this page!</Text>
        <Flex flexDirection="column" flex={1} my={4} minWidth="40%">
            <Text fontSize="sm">Title:</Text>
            {!notificationModel.title && <Text>*Required</Text>}
            <Input type="text" m={2} value={notificationModel.title} onChange={(e) => setNotificationModel({...notificationModel, title: e.target.value})} />
            <Text fontSize="sm">Body:</Text>
            {!notificationModel.body && <Text>*Required</Text>}
            <Input type="text" m={2} value={notificationModel.body} onChange={(e) => setNotificationModel({...notificationModel, body: e.target.value})} />
            {/* <Text fontSize="sm">Event Type:</Text>
            <Input type="text" m={2} value={notificationModel.eventType} onChange={(e) => setNotificationModel({...notificationModel, eventType: e.target.value})} /> */}
        </Flex>
        <Flex flexDirection="column" flex={1} my={4} minWidth="40%">
            <Text as="b" fontSize="sm">Data</Text>
            <Text fontSize="sm">Notification Type:</Text>
            <Input type="text" m={2} value={notificationData?.notificationType} onChange={(e) => setNotificationData({...notificationData, notificationType: e.target.value})} />
            <Text fontSize="sm">Command:</Text>
            <Input type="text" m={2} value={notificationData?.command} onChange={(e) => setNotificationData({...notificationData, command: e.target.value})} />
            <Text fontSize="sm">Entity ID:</Text>
            <Input type="text" m={2} value={notificationData?.entityId} onChange={(e) => setNotificationData({...notificationData, entityId: e.target.value})} />
        </Flex>
        <Button onClick={onOpen}>SEND</Button>
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
            <AlertDialogContent width="lg">
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Send Messages
                </AlertDialogHeader>

                <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                </Button>
                <Button colorScheme='red' onClick={handleSend} ml={3}>
                    Send
                </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>

    </Flex>
  );
}

export default BatchNotificationsScreen;
