/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link, useLocation, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Box, Flex, Slide, VStack, Button, Spinner } from '@chakra-ui/react';
import { UserCredential } from '@firebase/auth';
import { User as UserAuth } from 'firebase/auth';
import { UserAuth as UserAuthModel } from './models/UserAuth';
import { User } from './models/User';
import LoginScreen from './screens/Login';
import { auth } from './config/firebase';
import { CurrentUserContext } from './contexts/CurrentUserProvider';
import loggerService from './services/LoggerService';
import { authorizeAdmin, fetchUser } from './services/UsersService';
import PageLayout from './PageLayout';
import Logout from './screens/Logout';
import LoadingScreen from './components/LoadingScreen';


function RouteComponent() {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [user, loading, error] = useAuthState(auth);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    try {
      if (!user) return;
      setShowSpinner(true);
      const userCred: UserCredential = {
        user,
        providerId: null,
        operationType: 'signIn',
      };
      const userAuth: UserAuthModel = {
        uid: user.uid,
        identity: user,
      };
      const userResult = authorizeAdmin(userCred, userAuth as UserAuthModel)
        .then((res: any) => res.json())
        .then((result: User) => {
          console.log('authorizeAdmin result: ', result);
          if (result && result.userid) setCurrentUser(result);
          setShowSpinner(false);
        })
        .catch((err) => {
          loggerService.error(err);
          setShowSpinner(false);
        });
    } catch (err) {
      loggerService.debug(err);
      setShowSpinner(false);
    }
  }, [user, loading, error, setCurrentUser]);

  // useEffect(() => {
  //   if (loading) {
  //     // maybe trigger a loading screen
  //     return;
  //   }
  //   try {
  //     if (!user) return;
  //     if (!currentUser) return;
  //     setShowSpinner(true);
  //     const professionalResult = fetchProfessionalDetails(user, user?.uid)
  //       .then((res: any) => res.json())
  //       .then((result: ProfessionalUser) => {
  //         console.log('professional: ', result.userid, ' ', result.professionalid);
  //         if (result && result.professionalid) setCurrentProfessional(result);
  //         setShowSpinner(false);
  //       })
  //       .catch((err) => {
  //         console.log('error fetchProDetails: ', err);
  //         setCurrentProfessional(undefined);
  //         setShowSpinner(false);
  //       });
  //   } catch (err) {
  //     loggerService.debug(err);
  //     setShowSpinner(false);
  //   }
  // }, [user, currentUser, loading, error, setCurrentProfessional]);

  if (loading || showSpinner) return <LoadingScreen />;

  if (!user) {
    return (
      <Router>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/" element={<LoginScreen />} />
        </Routes>
      </Router>
    );
  }

  if (user && !currentUser) {
    return (
      <Router>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<LoginScreen />} />
        </Routes>
      </Router>
    );
  }

  // if (user && currentUser) {
  //   return (
  //     <Router>
  //       <Routes>
  //         <Route path="/login" element={<LoginScreen />} />
  //         <Route path="/" element={<Navigate to="/application" replace />} />
  //       </Routes>
  //     </Router>
  //   );
  // }

  // if user all good
  return (
    <Router>
      <PageLayout />
    </Router>
  );
}

export default RouteComponent;
