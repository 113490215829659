/* eslint-disable no-underscore-dangle */
import loggerService from '../services/LoggerService';

let USERS_BASE = '';
let REFERENCE_DATA_BASE = '';
let WARDROBE_BASE = '';

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//   USERS_BASE = 'http://127.0.0.1:5004/';
//   REFERENCE_DATA_BASE = 'http://127.0.0.1:5006/';
//   WARDROBE_BASE = 'http://127.0.0.1:5003/';
// } else {
//   // USERS_BASE = 'https://userservice-dot-high-gecko-263012.appspot.com/';
//   // REFERENCE_DATA_BASE = 'https://referencedataapi-dot-high-gecko-263012.appspot.com/';
//   // WARDROBE_BASE = 'https://wardrobeservice-dot-high-gecko-263012.appspot.com/';
//   USERS_BASE = 'https://user-service-l3er7yfyaa-uc.a.run.app/';
//   REFERENCE_DATA_BASE = 'https://referencedataapi-dot-high-gecko-263012.appspot.com/';
//   WARDROBE_BASE = 'https://wardrobe-service-l3er7yfyaa-uc.a.run.app/';
// }
USERS_BASE = process.env.REACT_APP_USERS_BASE as string;
REFERENCE_DATA_BASE = process.env.REACT_APP_REFERENCE_DATA_BASE as string;
WARDROBE_BASE = process.env.REACT_APP_WARDROBE_BASE as string;

console.log('USERS_BASE: ', USERS_BASE);
console.log('WARDROBE_BASE: ', WARDROBE_BASE);
console.log('REACT_APP_TEST_VAR: ', process.env.REACT_APP_TEST_VAR as string);

// USERS_BASE = 'https://userservice-dot-high-gecko-263012.appspot.com/';
// REFERENCE_DATA_BASE = 'https://referencedataapi-dot-high-gecko-263012.appspot.com/';
// WARDROBE_BASE = 'https://wardrobeservice-dot-high-gecko-263012.appspot.com/';

export const WARDROBE_API = `${WARDROBE_BASE}api/wardrobe`;
export const CLOSET_API = `${WARDROBE_BASE}api/closets`;
// export const WARDROBE_CLOSETS_API = `${WARDROBE_API}/{id}/closets`;
// export const WARDROBE_CLOSETS_ITEMS_API = `${WARDROBE_API}/{id}/closets/items`;
export const WARDROBE_ITEMS_API = `${WARDROBE_API}/{id}/items`;
export const FEED_SERVICE_API = `${WARDROBE_BASE}api/feed`;
export const OUTFIT_SERVICE_API = `${WARDROBE_BASE}api/outfit`;
export const UPLOAD_SERVICE_API = `${WARDROBE_BASE}api/imagestore/upload`;
export const TOUCHUP_SERVICE_API = `${WARDROBE_BASE}api/{id}/items/{wardrobeitemid}/touchup`;
export const PLANNER_SERVICE_API = `${WARDROBE_BASE}api/planner`;
export const GEO_SERVICE_API = `${WARDROBE_BASE}api/geo/`;
export const REVERSE_SEARCH_API = `${WARDROBE_BASE}/{id}/items/{wardrobeitemid}/reversesearch`;
export const LOOKBOOK_API = `${WARDROBE_BASE}api/lookbook`;
export const WARDROBE_ADMIN_API = `${WARDROBE_BASE}api/admin`;

export const USERS_SERVICE_API = `${USERS_BASE}api/users/`;
// export const USERS_SERVICE_FRIENDS_API = `${USERS_BASE}api/friend/`;
export const UPLOAD_AVATAR_API = `${USERS_SERVICE_API}/uploadavatar`;
export const USER_MEDIA_ASSET_SERVICE_API = `${USERS_BASE}api/usermediaasset/`;
export const NOTIFICATIONS_API = `${USERS_BASE}api/notifications`;
export const PAYMENTS_API = `${USERS_BASE}api/payments`;
export const MESSAGES_API = `${USERS_BASE}api/messages`;
export const SCHEDULE_API = `${USERS_BASE}api/schedule`;
export const PROFESSIONALS_API = `${USERS_BASE}api/professionals`;
export const ENGAGEMENTS_API = `${USERS_BASE}api/engagements`;
export const INVOICE_API = `${USERS_BASE}api/invoices`;
export const USER_ADMIN_API = `${USERS_BASE}api/admin`;
export const FEATURED_CONTENT_API = `${WARDROBE_BASE}api/featuredcontent`;

export const REFERENCE_DATA_API = `${REFERENCE_DATA_BASE}api/referencedata`;

export const API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
// export const AUTH_DOMAIN = '';
export const PROJECT_ID = process.env.REACT_APP_GOOGLE_PROJECT_ID;
// export const STORAGE_BUCKET = '';
// export const MESSAGING_SENDER_ID = '';
// export const APP_ID = '';

export const AUTH_ERR_CODES: { [name: string]: string } = {
  'auth/email-already-exists': 'The provided email is in use by an existing user. If this email belongs to you, please login with that email.',
  'auth/invalid-email': 'The provided email for the user is invalid. Please enter a valid email address.',
  'auth/invalid-password': 'The provided password is invalid. Please try again.',
  'auth/invalid-phone-number': 'The format of the phone number provided is incorrect. Please enter the phone number in the format (+1)000-000-0000',
  'auth/user-not-found': 'There is no known user for the given username or email.',
  'auth/weak-password': 'The provided password does not meet minimum requirements. It must be at least 6 letters. Remember, the longer the better.',
  'auth/wrong-password': 'The password is invalid or the user does not have a password.',
  'auth/user-signed-out': 'You must be signed in to perform the requested action.',
  'auth/unverified-email': 'This email address has not yet been verified.',
  'auth/missing-phone-number': 'To send verification codes, provide a phone number for the recipient.',
  'auth/missing-multi-factor-info': 'No second factor identifier is provided.',
  'auth/missing-multi-factor-session': 'The request is missing proof of first factor successful sign-in.',
};

export const INSUFFICIENT_CREDENTIALS_MSG = 'You must fill out both your email and password.';

// export const GOOGLE_AUTH_CLIENT_ID_WEB = '221510181069-0q338it137ps5nto2laq268k1sus2tm4.apps.googleusercontent.com';

// export const GOOGLE_AUTH_CLIENT_ID_IOS = '221510181069-j8uc2bdj07frjikkou1ito6hecqajdo0.apps.googleusercontent.com';

// export const GOOGLE_AUTH_CLIENT_ID_ANDROID = '221510181069-0o1ac4m61pn959c65bkgmlmige0ge2bj.apps.googleusercontent.com';

// export const GOOGLE_AUTH_CLIENT_ID = () => {
//   const token = GOOGLE_AUTH_CLIENT_ID_WEB;
//   return token;
// };

export const FIREBASE_IMAGE_BASE_URL = 'firebasestorage.googleapis.com';

export const GOOGLE_CALENDAR_API_KEY = 'AIzaSyBNvkJKJbbblpUxwW7diFLY38NaG9qhxk4';

export const SENTRY_DSN = 'https://be22cdd9da294c77bcb3675a4535cae6@o1158135.ingest.sentry.io/6241017';

export const OUTFIT_DISPLAY_HEIGHT = 380;
