import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Outfit } from '../models/Outfit';
import { OutfitDecorator } from '../models/OutfitDecorator';
import { OutfitPart as OutfitPartModel } from '../models/OutfitPart';

type OutfitPartsDisplayProps = {
  outfit: Outfit;
  height: number;
  width: number | string;
  resizeRatio: number; // TODO: decide if I want this to be a prop or calc automatically based on const OUTFIT_DISPLAY_HEIGHT
};

// function Header({ handleHamburgerToggle }: HeaderProps) {
function OutfitPartsDisplay({ outfit, height, width, resizeRatio }: OutfitPartsDisplayProps): React.ReactElement {
  return (
    <Box h={height} w={width} position="relative">
      {outfit &&
        outfit.parts &&
        outfit?.parts
          .sort((a, b) => (a.sortorder > b.sortorder ? 0 : -1))
          .map((part: OutfitPartModel) => (
            <Box
              key={part.id}
              display="flex"
              flex={1}
              position="absolute" // or relative???
              top={`${part.top * resizeRatio}px`}
              left={`${part.left * resizeRatio}px`}
              // top={part.top * resizeRatio}
              // left={part.left * resizeRatio}
              // transform: [{ rotate: `${part.rotation}deg` }]
            >
              {/* <Image h={part.height * resizeRatio} w={part.width * resizeRatio} zIndex={part.sortorder} src={part.imageUrl} fit="contain" alt="wardrobe item" /> */}
              <Image
                //h={part.height * resizeRatio}
                h={`${part.height * resizeRatio}px`}
                fit="contain"
                zIndex={part.sortorder}
                src={part.imageUrl}
                alt="wardrobe item"
                transform={part.rotation ? `rotate(${part.rotation}deg)` : undefined}
              />
            </Box>
          ))}
      {outfit &&
        outfit?.decorators &&
        outfit?.decorators
          .sort((a, b) => (a.sortorder > b.sortorder ? 0 : -1))
          .map((part: OutfitDecorator) => (
            <Box
              key={part.id + 100}
              display="flex"
              flex="1"
              position="absolute"
              top={part.top * resizeRatio}
              left={part.left * resizeRatio}
              // transform: [{ rotate: `${part.rotation}deg` }]
              transform={part.rotation ? `rotate(${part.rotation}deg)` : undefined}
            >
              <Text fontSize={part.size * resizeRatio} zIndex={part.sortorder}>
                {part.content}
              </Text>
            </Box>
          ))}
    </Box>
  );
}

export default OutfitPartsDisplay;
