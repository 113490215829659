/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { User as UserAuth } from 'firebase/auth';
import fetchWrapper from './fetchWrapper';
import { FEATURED_CONTENT_API } from '../config/ServiceConstants';
import { FeaturedContentTopic, FeaturedContentTopicItemsQuery } from '../models/Featured';

export function fetchFeaturedContentTopics(userAuth: UserAuth): Promise<any> {
  const url = `${FEATURED_CONTENT_API}/alltopics`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchFeaturedContentTopicTags(userAuth: UserAuth): Promise<any> {
  const url = `${FEATURED_CONTENT_API}/tags`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchFeaturedContentTopicItem(userAuth: UserAuth, id: number): Promise<any> {
  const url = `${FEATURED_CONTENT_API}/topicitems/${id}`;
  return fetchWrapper(userAuth).get(url);
}

export function searchFeaturedContentTopicItems(userAuth: UserAuth, query: FeaturedContentTopicItemsQuery): Promise<any> {
  let url = `${FEATURED_CONTENT_API}/topicitems/search`;
  if (query) {
    url += '?';
    if (query.po && query.po > 0) {
      url += `&po=${query.po}`; // page offset, defaults to 0 for first page
    }
    if (query.ps) {
      url += `&ps=${query.ps}`; // page size, e.g. items per page
    }
    if (query.sf) {
      url += `&sf=${query.sf}`; // sort field, e.g. modifieddate
    }
    if (query.sd) {
      url += `&sd=${query.sd}`; // sort direction, e.g. asc or desc
    }
    if (query.createdby) {
      url += `&createdby=${query.createdby}`;
    }
    if (query.createdbyusername) {
      url += `&createdbyusername=${query.createdbyusername}`;
    }
    if (query.status) {
      url += `&status=${query.status}`;
    }
    if (query.tags && query.tags.length > 0) {
      url += `&tags=${query.tags}`;
    }
  }
  return fetchWrapper(userAuth).get(url);
}

export function createFeaturedContentTopic(userAuth: UserAuth, model: FeaturedContentTopic): Promise<any> {
  const url = `${FEATURED_CONTENT_API}`;
  return fetchWrapper(userAuth).post(url, model);
}

export function updateFeaturedContentTopic(userAuth: UserAuth, model: FeaturedContentTopic): Promise<any> {
  const url = `${FEATURED_CONTENT_API}/${model.id}`;
  return fetchWrapper(userAuth).put(url, model);
}

export async function uploadFeaturedContentCoverImage(userAuth: UserAuth, file: File): Promise<any> {
  const data: FormData = new FormData();
  data.append('imageFile', file);
  const token = await userAuth.getIdToken();
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
      'File-Name': file.name,
    },
    body: data,
  };
  const url = `${FEATURED_CONTENT_API}/uploadimage`;
  return fetch(url, requestOptions);
}
