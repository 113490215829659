import {
    HStack,
    Text,
    Flex,
    Heading,
    VStack,
    Image,
    InputLeftElement,
    Input,
    InputGroup,
    Box,
    Portal,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Avatar,
    Spinner,
  } from '@chakra-ui/react';
  import { useAuthState } from 'react-firebase-hooks/auth';
  import React, { useContext, useEffect, useState } from 'react';
  import { auth } from '../config/firebase';
  import { CurrentUserContext } from '../contexts/CurrentUserProvider';
  import { AdminDashboardModel, RecommendationResultStats } from '../models/AdminDashboardModel';
  import { fetchUserServiceDashboard, fetchWardrobeServiceDashboard } from '../services/DashboardService';
  
  function AdminDashboard() {
    const [user] = useAuthState(auth);
    const { currentUser } = useContext(CurrentUserContext);
    const [loading, setLoading] = useState(true);
    const [model, setModel] = useState<AdminDashboardModel>({});

    const fetchUserDashboardStats = async () => {
        if (!user) return;
        await fetchUserServiceDashboard(user)
            .then((res) => res.json())
            .then((result: AdminDashboardModel) => {
                const clone = structuredClone(model);
                clone.totalEndUsersCount = result.totalEndUsersCount;
                clone.numberUniqueLoginsInLastWeek = result.numberUniqueLoginsInLastWeek;
                clone.totalProfessionalsCount = result.totalProfessionalsCount;
                clone.totalSocialPostsCount = result.totalSocialPostsCount;
                clone.totalProEngagementCount = result.totalProEngagementCount;
                clone.totalActiveEngagementsCount = result.totalActiveEngagementsCount;
                // setModel(clone);
                return clone;
            })
            .then((userresult: AdminDashboardModel) => {
                fetchWardrobeServiceDashboard(user)
                    .then((res) => res.json())
                    .then((wardroberesult: AdminDashboardModel) => {
                        console.log(wardroberesult);
                        userresult.totalWardrobeItemsCount = wardroberesult.totalWardrobeItemsCount;
                        userresult.totalOufitsCount = wardroberesult.totalOufitsCount;
                        userresult.totalLookbooksCount = wardroberesult.totalLookbooksCount;
                        userresult.recommendationResultStats = wardroberesult.recommendationResultStats;
                        setModel(userresult);
                    });
            })
            .catch((err) => console.log(err))
      };
    
    useEffect(() => {
        if (!user) return;
        fetchUserDashboardStats().then(() => setLoading(false)).catch(console.error);
    }, [user]);

    const renderStatDisplay = (title: string, value: any) => {
        return (
            <Flex flexDirection="column" alignItems="center" p={4} m={4} borderRadius="lg" borderWidth={1} bg="blue.400" color="orange.400">
                <Text fontWeight="bold" fontSize="xl">{title}</Text>
                <Text fontSize="lg">{value}</Text>
            </Flex>
        )
    }

    const renderRecoResultsStatDisplay = (recommendationResultStats: RecommendationResultStats | undefined) => {
      console.log('recos: ', recommendationResultStats);
      return (
          <Flex flexDirection="column" alignItems="center" p={4} m={4} borderRadius="lg" borderWidth={1} bg="blue.400" color="orange.400">
              <Text fontWeight="bold" fontSize="xl">OOTD Actions:</Text>
              <HStack>
                <Text fontSize="xs">Saved:</Text>
                <Text fontSize="xs">{recommendationResultStats?.ootdS_saved}</Text>
              </HStack>
              <HStack>
                <Text fontSize="xs">Liked:</Text>
                <Text fontSize="xs">{recommendationResultStats?.ootdS_liked}</Text>
              </HStack>
              <HStack>
                <Text fontSize="xs">Disliked:</Text>
                <Text fontSize="xs">{recommendationResultStats?.ootdS_disliked}</Text>
              </HStack>
          </Flex>
      )
    }
    
    if (loading) return <Spinner />;
  
    return (
      <>
        <Flex flex={1} direction="row" justifyContent="space-between" p={6} width="100%">
            <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center" width="100%">
              <Text display="flex" fontSize="xl">Admin Dashboard</Text>
              <Flex flex={1} flexDir="row" flexWrap="wrap" mt={4} mx={4}>
                {renderStatDisplay("Total Active Users:", model.totalEndUsersCount)}
                {renderStatDisplay("Total Professionals:", model.totalProfessionalsCount)}
                {renderStatDisplay("Logins Last Week:", model.numberUniqueLoginsInLastWeek)}
                {renderStatDisplay("Total Number Posts:", model.totalSocialPostsCount)}
                {renderStatDisplay("Total Wardrobe Items:", model.totalWardrobeItemsCount)}
                {renderStatDisplay("Total Outfits:", model.totalOufitsCount)}
                {renderStatDisplay("Total Lookbooks:", model.totalLookbooksCount)}
                {renderStatDisplay("Total Engagements:", model.totalProEngagementCount)}
                {renderStatDisplay("Total Active Engagements:", model.totalActiveEngagementsCount)}
                {renderRecoResultsStatDisplay(model.recommendationResultStats)}
              </Flex>
            </Flex>
        </Flex>
      </>
    );
  }
  
  export default AdminDashboard;
  