/* eslint-disable import/prefer-default-export */
import { UserCredential } from '@firebase/auth';
import { User as UserAuth } from 'firebase/auth';
import fetchWrapper from './fetchWrapper';
import { USERS_SERVICE_API } from '../config/ServiceConstants';
import { User, UserSizeData } from '../models/User';
import loggerService from './LoggerService';
import { UserAuth as UserAuthModel } from '../models/UserAuth';
import { UserQuery } from '../models/UserQuery';
import { PaginatedResult } from '../models/PaginatedResult';

export function fetchUser(userAuth: UserAuth, userId: string): Promise<any> {
  const url = `${USERS_SERVICE_API}${userId}`;
  return fetchWrapper(userAuth).get(url);
}

export function updateUser(userAuth: UserAuth, user: User): Promise<any> {
  const url = `${USERS_SERVICE_API}${user.userid}`;
  loggerService.debug('updating user: ', url);
  return fetchWrapper(userAuth).put(url, user);
}

export async function uploadUserProfilePic(userAuth: UserAuth, file: File): Promise<any> {
  const data: FormData = new FormData();
  data.append('imageFile', file);
  const token = await userAuth.getIdToken();
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      // 'Content-Type': 'multipart/form-data',
      'File-Name': file.name,
    },
    body: data,
  };
  const url = `${USERS_SERVICE_API}uploadavatar`;
  return fetch(url, requestOptions);
}

// export async function ensureUser(user: UserCredential, userAuth: UserAuthModel): Promise<any> {
//   console.log('ensureUser.UserCredential: ', user);
//   console.log('ensureUser.userAuth: ', userAuth);
//   // const userId = user.user.uid;
//   const token = await user.user.getIdToken();
//   console.log('token: ', token);
//   const requestOptions = {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${token}`,
//     },
//     body: JSON.stringify(user),
//   };
//   const url = `${USERS_SERVICE_API}`;
//   console.log('userservice.ensuring user: ', url);
//   return fetch(url, requestOptions);
// }


export async function authorizeAdmin(user: UserCredential, userAuth: UserAuthModel): Promise<any> {
  // console.log('ensureUser.UserCredential: ', user);
  // console.log('ensureUser.userAuth: ', userAuth);
  // const userId = user.user.uid;
  const token = await user.user.getIdToken();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(user),
  };
  const url = `${USERS_SERVICE_API}admin`;
  // console.log('userservice.authorizing admin: ', url);
  return fetch(url, requestOptions);
}

export async function updatePushNotificationToken(userAuth: UserAuth, token: string): Promise<any> {
  const url = `${USERS_SERVICE_API}${userAuth.uid}/pnt`;
  return fetchWrapper(userAuth).post(url, token);
}

export async function updateUserSizeData(userAuth: UserAuth, userid: string, data: UserSizeData): Promise<any> {
  const url = `${USERS_SERVICE_API}${userid}/usersizedata`;
  return fetchWrapper(userAuth).put(url, data);
}

export function searchUsers(userAuth: UserAuth, query: UserQuery): Promise<any> {
  const url = `${USERS_SERVICE_API}${userAuth.uid}/search`;
  return fetchWrapper(userAuth).post(url, query);
}

export function fetchUserFollowCounts(userAuth: UserAuth, userid: string): Promise<any> {
  const url = `${USERS_SERVICE_API}${userid}/followcounts`;
  loggerService.debug('fetchUserFollowCounts.url: ', url);
  return fetchWrapper(userAuth).get(url);
}

export function followUser(userAuth: UserAuth, userid: string, targetUserId: string): Promise<any> {
  const url = `${USERS_SERVICE_API}${userAuth.uid}/follow-user/${targetUserId}`;
  loggerService.debug(url);
  return fetchWrapper(userAuth).post(url);
}

export function unfollowUser(userAuth: UserAuth, userid: string, targetUserId: string): Promise<any> {
  const url = `${USERS_SERVICE_API}${userAuth.uid}/unfollow-user/${targetUserId}`;
  return fetchWrapper(userAuth).post(url);
}
