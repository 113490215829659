/* eslint-disable import/prefer-default-export */
import { User as UserAuth } from 'firebase/auth';
import moment from 'moment';
import fetchWrapper from './fetchWrapper';
import { PROFESSIONALS_API } from '../config/ServiceConstants';
import { Application } from '../models/Application';

export function fetchPendingApplications(userAuth: UserAuth): Promise<any> {
    const url = `${PROFESSIONALS_API}/applications/pending`;
    return fetchWrapper(userAuth).get(url);
}
  
export function putApproveApplication(userAuth: UserAuth, applicationid: number): Promise<any> {
  const url = `${PROFESSIONALS_API}/applications/${applicationid}/status`;
  return fetchWrapper(userAuth).put(url, 'approved');
}

export function putDeclineApplication(userAuth: UserAuth, applicationid: number): Promise<any> {
  const url = `${PROFESSIONALS_API}/applications/${applicationid}/status`;
  return fetchWrapper(userAuth).put(url, 'declined');
}