import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import RouteComponent from './RouteComponent';
import { CurrentUserProvider } from './contexts/CurrentUserProvider';
import donnetheme from './theme';
import Fonts from './theme/foundations/customfonts';

function App() {
  return (
    <ChakraProvider theme={donnetheme}>
      <Fonts />
      <CurrentUserProvider>
            <RouteComponent />
      </CurrentUserProvider>
    </ChakraProvider>
  );
}

export default App;
