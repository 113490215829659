import { Outfit } from './Outfit';

// DEPRECATED
// export interface FeaturedContentItem {
//   id: number;
//   contenttype: string; // outfit, image, video
//   title: string;
//   description?: string;
//   link?: string;
//   imageurl?: string;
//   videourl?: string;
//   outfit?: Outfit;
//   hashtags?: string[];
// }

export interface CollageItem {
  id: number;
  imageurl: string;
  link: string;
  brandname: string;
}

export interface FeaturedContentTopicItem {
  id: number;
  contenttype: string; // outfit, image, collage, video
  createddate: Date;
  createdbyuserid: string;
  createdbyname: string;
  status: string; // draft, submitted, accepted,
  title?: string;
  description?: string;
  link?: string;
  imageurl?: string;
  collageimageurl?: string;
  collageItems?: CollageItem[];
  outfit?: Outfit;
  videourl?: string;
  hashtags?: string[];
}

export interface FeaturedContentTopic {
  id: number;
  title: string;
  description?: string;
  isActive: boolean;
  coverImageUrl: string;
  hashtags?: string[];
  contentItemIds: number[];
  contentItems: FeaturedContentTopicItem[];
  createddate?: Date;
  createdby: string;
}

export interface FeaturedContentTopicItemsQuery {
  /**
   * sort field
   */
  sf?: string;
  /**
   * sort direction (ASC or DESC)
   */
  sd?: string;
  /**
   * page size
   */
  ps?: number;
  /**
   * page offset
   */
  po?: number;
  /**
   * userid of the user that created the outfit (only if different from the owner)
   */
  createdby?: string;
  createdbyusername?: string;
  tags?: string[];
  status?: string;
}

export const DefaultFeaturedContentTopicItemsQuery = {
  po: 1,
  ps: 40,
  sf: 'createddate',
  sd: 'desc',
  createdby: undefined,
  tags: undefined,
  status: 'Submitted',
};

