import moment from 'moment';
import { Text, HStack, Flex, Spinner, useDisclosure } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { createColumnHelper } from '@tanstack/react-table';
import { DataTable } from '../components/DataTable';
import LoadingScreen from '../components/LoadingScreen';
import { fetchFeaturedContentTopics } from '../services/FeaturedContentService';
import { FeaturedContentTopic } from '../models/Featured';
import FeaturedContentTopicDetails from '../components/FeaturedContentTopicDetails';
import { AddIcon } from '@chakra-ui/icons';


function FeaturedContentScreen() {
  const { currentUser } = useContext(CurrentUserContext);
  const [user] = useAuthState(auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [featuredContentTopics, setFeaturedContentTopics] = useState<FeaturedContentTopic[] | undefined>();
  const [selectedTopic, setSelectedTopic] = useState<FeaturedContentTopic | undefined>();

  const fetch = async () => {
    if (!user) return;
    await fetchFeaturedContentTopics(user)
      .then((res: { json: () => any }) => res.json())
      .then((result: FeaturedContentTopic[]) => {
        const sorted = result.sort((a, b) => (a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1))
        setFeaturedContentTopics(sorted);
        console.log(result);
    });
  };

  useEffect(() => {
    if (!user) return;
    fetch().catch(console.error);
  }, [user]);


  type featuredcontentTableDisplayData = {
    title: string;
    description?: string;
    isActive: boolean;
    coverImageUrl: string;
    hashtags?: string[];
    createddate?: Date;
    createdby: string;
    contentItemsCount: number;
    id: number;
  };

  const tabledata: featuredcontentTableDisplayData[] = !featuredContentTopics ? [] : featuredContentTopics.map((a: FeaturedContentTopic) => ({
      title: a.title,
      description: a.description,
      isActive: a.isActive,
      coverImageUrl: a.coverImageUrl,
      hashtags: a.hashtags,
      createddate: a.createddate,
      createdby: a.createdby,
      contentItemsCount: a.contentItems?.length,
      id: a.id as number,
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedtabledata = useMemo(() => tabledata, [featuredContentTopics]);
  const columnHelper = createColumnHelper<featuredcontentTableDisplayData>();
  const columns = [
    columnHelper.accessor('title', {
      cell: (info: any) => info.getValue(),
      header: 'Title',
    }),
    columnHelper.accessor('description', {
        cell: (info: any) => info.getValue(),
        header: 'Description',
      }),
    columnHelper.accessor('hashtags', {
        cell: (info: any) => info.getValue() && info.getValue().length > 0 ? info.getValue().join(', ') : '',
        header: 'Tags',
      }),
    columnHelper.accessor('isActive', {
        cell: (info: any) => info.getValue() as boolean === true ? "Yes" : "No",
        header: 'Is Active?',
      }),
    columnHelper.accessor('createddate', {
      cell: (info: any) => moment(info.getValue()).format('MMM DD'),
      header: 'Date Created',
      meta: {
        isDate: true,
      },
    }),
    columnHelper.accessor('contentItemsCount', {
        cell: (info: any) => info.getValue() as number,
        header: 'Items Count',
      }),
  ];

  const rowSelectedHandler = (index: number) => {
    if (!featuredContentTopics) return;
    const topicid = tabledata[index].id;
    const topic = featuredContentTopics.filter((e: FeaturedContentTopic) => e.id === topicid)[0];
    setSelectedTopic(topic);
    onOpen();
  };

  if (!featuredContentTopics || !user) return <LoadingScreen />;

  if (selectedTopic) return <FeaturedContentTopicDetails topic={selectedTopic} handleClose={() => setSelectedTopic(undefined)} />;

  return (
    <Flex flexDirection="column" height="full" width="100%" flex={1} px={12} py={8} alignItems="center">
        <HStack>
          <Text variant="header">Featured Content Topics</Text>
          <AddIcon onClick={() => setSelectedTopic({ id:0, title: '(title)', description: undefined, isActive: false, coverImageUrl: '', createdby: user.uid, contentItemIds: [], contentItems: [] })} />
        </HStack>
        <DataTable columns={columns} data={memoizedtabledata} shadeAlternatingRows={false} handleRowSelected={rowSelectedHandler} />
    </Flex>
  )
}

export default FeaturedContentScreen;