/* eslint-disable no-console */
import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  OAuthProvider,
  signOut,
} from 'firebase/auth';
import { SUCCESS, INVALID_EMAIL, USER_NOT_FOUND, WRONG_PASSWORD, GENERIC_LOGIN_ERROR, INTERNAL_ERROR } from './LoginConstants';

// const firebaseConfig = {
//   apiKey: 'AIzaSyAZrRq4PoLCNISy-jRCW80UhFO7RKHSe5g',
//   authDomain: 'high-gecko-263012.firebaseapp.com',
//   projectId: 'high-gecko-263012',
//   storageBucket: 'high-gecko-263012.appspot.com',
//   messagingSenderId: '221510181069',
//   appId: '1:221510181069:web:b9f7b4a6b3d7959cab8984',
//   measurementId: 'G-WXY17E706N',
// };
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_GOOGLE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

console.log('REACT_APP_TEST_VAR: ', process.env.REACT_APP_TEST_VAR);
console.log('Firebase.projectId: ', process.env.REACT_APP_GOOGLE_PROJECT_ID);
console.log('Firebase.apikey: ', process.env.REACT_APP_GOOGLE_PROJECT_ID);

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');

const getErrorCode = (err) => {
  switch (err.code) {
    case 'auth/invalid-email':
      return INVALID_EMAIL;
    case 'auth/user-not-found':
      return USER_NOT_FOUND;
    case 'auth/wrong-password':
      return WRONG_PASSWORD;
    case 'auth/invalid-credential-or-provider-id': // sign in with Apple error
    case 'auth/internal-error':
    default:
      return GENERIC_LOGIN_ERROR;
  }
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const { user } = res;
    console.log(user);
    return SUCCESS;
  } catch (err) {
    console.error(err);
    return getErrorCode(err);
  }
};

const signInWithApple = async () => {
  try {
    const res = await signInWithPopup(auth, appleProvider);
    const { user } = res;
    console.debug(user);
    return SUCCESS;
  } catch (err) {
    console.error(err);
    return getErrorCode(err);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const userResult = await signInWithEmailAndPassword(auth, email, password);
    console.debug(userResult);
    return SUCCESS;
  } catch (err) {
    console.error(err);
    return getErrorCode(err);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = res;
    console.debug(user);
  } catch (err) {
    console.error(err);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (err) {
    console.error(err);
  }
};

const logout = () => {
  signOut(auth);
  console.info('signed out');
};

export { app, auth, signInWithGoogle, signInWithApple, logInWithEmailAndPassword, registerWithEmailAndPassword, sendPasswordReset, logout };
