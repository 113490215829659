import moment from 'moment';
import { Text, Input, HStack, Flex, Select, Button, Highlight, Spinner, useDisclosure, Center, Image, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Box, Checkbox } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import LoadingScreen from '../components/LoadingScreen';
import { fetchPendingApplications, putApproveApplication, putDeclineApplication } from '../services/ApplicationsService';
import { Application } from '../models/Application';
import { useMemo } from 'react';
import { ProfessionalUser } from '../models/ProfessionalUser';
import { getAllProfessionals, patchIsFeatured, searchProfessionals } from '../services/ProfessionalsService';
import { ProfessionalsQuery } from '../models/ProfessionalsQuery';
import { PaginatedResult } from '../models/PaginatedResult';
import { profile } from 'console';


function ProfessionalsScreen() {
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pros, setPros] = useState<ProfessionalUser[]>([]);
  const [selectedPro, setSelectedPro] = useState<ProfessionalUser | undefined>();

  const fetch = async () => {
    if (!user) return;
    // const q: ProfessionalsQuery = {
    //     serviceTypes: ['All'],
    //     latitude: undefined,
    //     longitude: undefined,
    //     maxDistance: undefined,
    //     maxprice: undefined,
    //     minrating: undefined,
    //     // inPerson: true,
    //     // doesVirtual: true,
    //     availability: 'Any Time',
    //     style: 'Open to Any Style',
    //     pageSize: 300,
    //     requestedPage: 1,
    //     queryTerm: undefined,
    //   };
    //   await searchProfessionals(user, q)
    //     .then((res) => res.json())
    //     .then((pagedResult: PaginatedResult) => {
    //       setPros(pagedResult.pageItems);
    //     });
    await getAllProfessionals(user)
      .then((res) => res.json())
      .then((pagedResult: PaginatedResult) => {
        setPros(pagedResult.pageItems);
      });
  };

  useEffect(() => {
    if (!user) return;
    fetch().catch(console.error);
  }, [user]);

  const renderProDetailsRow = (pro: ProfessionalUser) => {
    return (
        <Flex key={pro.professionalid} borderBottomWidth={1} p={2} flex={1}justifyContent="space-between" onClick={() => {
          setSelectedPro(pro);
          onOpen();
        }}>
            <Text flex={1}>{pro.firstname}</Text>
            <Text flex={1}>{pro.lastname}</Text>
            <Text flex={1}>{pro.state}</Text>
            <Text flex={1}>${pro.avg_hourly_rate}</Text>
            <Text flex={1}>{pro.visible_to_clients ? "ACTIVE" : "INACTIVE"}</Text>
            {pro.visible_to_clients &&
              <Checkbox
                  flex={1}
                  colorScheme="orange"
                  iconColor="blue.400"
                  iconSize={2}
                  isChecked={pro.isfeatured}
                  onChange={(e) => toggleProIsFeatured(pro.userid, e.target.checked)}
                  // value={pro.isfeatured}
              />
            }
            {!pro.visible_to_clients && <Text flex={1}>NA</Text>}
        </Flex>
    )
  }

  const renderProDetails = () => {
    return (
      <Flex>
        <Text>{JSON.stringify(selectedPro)}</Text>
      </Flex>
    )
  }

  const toggleProIsFeatured = async (userid: string, isFeatured: boolean) => {
    onClose();
    var featuredval = isFeatured ? 1 : -1;
    await patchIsFeatured(user as UserAuth, userid, featuredval).then(() => {
        const clone = structuredClone(pros);
        const pro = clone.filter((e: ProfessionalUser) => e.userid === userid)[0];
        pro.isfeatured = isFeatured;
        setPros(clone);
    });
  }

  if (!user) return <LoadingScreen />;

  return (
    <Flex flexDirection="column" height="full" width="100%" flex={1} px={12} py={8} alignItems="center">
        <Text variant="header">Set Featured Professionals</Text>
        <Flex my={4} flexDirection="column" flex={1} width="100%">
        <Flex borderBottomWidth={4} p={2} justifyContent="space-between" flex={1}>
            <Text flex={1}>First</Text>
            <Text flex={1}>Last</Text>
            <Text flex={1}>State</Text>
            <Text flex={1}>Avg Rate</Text>
            <Text flex={1}>Visible?</Text>
            <Text flex={1}>Featured?</Text>
        </Flex>
            {pros && pros.map((pro) =>  {
                return (
                    renderProDetailsRow(pro)
                );
            })
            }
        </Flex>
        <Drawer placement="right" size="xl" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent bg="white">
            <DrawerHeader borderBottomWidth="1px">
              <Text variant="header" color="black">
                Pro Details
              </Text>
            </DrawerHeader>
            <DrawerBody mt={2}>{renderProDetails()}</DrawerBody>
          </DrawerContent>
        </Drawer>
    </Flex>
  );
}

export default ProfessionalsScreen;
