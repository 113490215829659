import { defineStyleConfig } from '@chakra-ui/react';

const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'bold',
    background: 'blue.400',
    color: 'charcoal',
    borderRadius: 'base', // <-- border radius is same for all variants and sizes
    _focusVisible: {
      boxShadow: 0,
    },
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: 'md',
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },
  // Four variants: outline, darkoutline, solid, and solidbright
  variants: {
    outline: {
      border: '1px solid',
      borderColor: 'blue.400',
      color: 'blue.700',
      bg: 'white',
      borderRadius: '2xl',
      _hover: {
        background: 'blue.200',
      },
      _focus: {
        background: 'blue.200',
      },
      _active: {
        background: 'blue.200',
      },
    },
    darkoutline: {
      border: '1px solid',
      borderColor: 'charcoal',
      color: 'charcoal',
      bg: 'white',
      borderRadius: '2xl',
      _hover: {
        background: 'blue.200',
      },
      _focus: {
        background: 'blue.200',
      },
      _active: {
        background: 'blue.200',
      },
    },
    solid: {
      bg: 'blue.400',
      color: 'charcoal',
      borderRadius: '2xl',
      borderWidth: 0,
      _hover: {
        background: 'blue.200',
      },
      _focus: {
        background: 'blue.200',
      },
      _active: {
        background: 'blue.200',
      },
      _focusVisible: {
        boxShadow: 0,
      },
    },
    solidbright: {
      bg: 'blue.600',
      color: 'white',
      borderRadius: '2xl',
      borderWidth: 0,
      _hover: {
        background: 'blue.200',
      },
      _focus: {
        background: 'blue.200',
      },
      _active: {
        background: 'blue.200',
      },
      _focusVisible: {
        boxShadow: 0,
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
});

export default Button;
