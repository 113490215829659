import { Flex, Avatar, Center, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, Image, Spinner } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../config/firebase';
import { EditIcon } from '@chakra-ui/icons';
import { uploadFeaturedContentCoverImage } from '../services/FeaturedContentService';

type FeaturedContentTopicSelectableImageProps = {
  url: string;
  handleImageChanged: (newvalue: string) => void;
};

function FeaturedContentTopicSelectableImage({ url, handleImageChanged }: FeaturedContentTopicSelectableImageProps) {
  const [user] = useAuthState(auth);
  const [showSpinner, setShowSpinner] = useState(false);
  const {openFilePicker, filesContent, plainFiles, errors, clear: clearFilePicker } = useFilePicker({
    readAs: 'DataURL',
    accept: ['image/*', '.png', '.jpg', 'jpeg'],
    multiple: false,
    limitFilesConfig: { max: 1 },
    // minFileSize: 0.1, // in megabytes
    maxFileSize: 20,
    imageSizeRestrictions: {
      maxHeight: 2000, // in pixels
      maxWidth: 2000,
      minHeight: 20,
      minWidth: 20,
    },
  });

  const formattedErrors = () => {
    if (!errors || errors.length === 0) return '';
    let errmsg = 'Errors: ';
    errors.forEach((e: any) => {
      if (e.fileSizeToolarge) errmsg = 'Error: File is too large (> 20 MB).';
      if (e.imageHeightTooBig) errmsg = 'Error: Max height and width is 2000px';
      if (e.imageWidthTooBig) errmsg = 'Error: Max height and width is 2000px';
    });
    return errmsg;
  };

  if (showSpinner) return <Spinner />;

  return (
    <>
      <Flex position="relative">
        <Image src={url} boxSize="2xs" mb={4} />
        <EditIcon mx={2} onClick={openFilePicker} />
      </Flex>
      <Modal size="sm" isCentered isOpen={(plainFiles && plainFiles[0] !== undefined) || (errors && errors.length > 0)} onClose={clearFilePicker}>
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>Upload Cover Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDir="column" justifyContent="center" alignItems="center">
              {/* <Avatar src={filesContent[0]?.content} size="2xl" mb={4} /> */}
              <Image src={filesContent[0]?.content} h={32} w={32} mb={4} />
            </Flex>
            <Center>
              <Text color="red.400">{formattedErrors()}</Text>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" flex={1}>
              <Button
                variant="darkoutline"
                onClick={() => {
                  clearFilePicker();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                onClick={() => {
                  if (!user) return;
                  setShowSpinner(true);
                  uploadFeaturedContentCoverImage(user, plainFiles[0])
                    .then((res) => res.json())
                    .then((r) => {
                      handleImageChanged(r);
                      console.log('imageurl:', r);
                      clearFilePicker();
                    })
                    .catch((e) => console.log(e))
                    .finally(() => setShowSpinner(false));
                }}
              >
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default FeaturedContentTopicSelectableImage;
