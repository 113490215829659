import moment from 'moment';
import { Box, Text, Heading, HStack, Flex, Select, Button, Highlight, Spinner, Avatar, Center, useToast, Link } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import LoadingScreen from '../components/LoadingScreen';
import AdminDashboard from '../components/AdminDashboard';

function HomeScreen() {
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const toast = useToast();
  const [user, loading, error] = useAuthState(auth);

  const renderNavButton = (title: string, path: string) => {
    return (
      <Flex flex={1} p={1} mx={2} justifyContent="center" bg="blue.400">
        <Text fontWeight="bold" color="brown.400" p={1} as="u" onClick={() => navigate(path)}>{title}</Text>
      </Flex>
    )
  }

  if (!user) return <LoadingScreen />;

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex flexDirection="row" flex={1} px={12} py={8}>
          {renderNavButton('Reclassify', 'reclassify')}
          {renderNavButton('Applications', 'applications')}
          {renderNavButton('Professionals', 'professionals')}
          {renderNavButton('Featured Content', 'featuredcontent')}
          {renderNavButton('Notifications', 'notifications')}
      </Flex>
      <Flex flex={1}>
        <AdminDashboard />
      </Flex>
    </Flex>

  );
}

export default HomeScreen;
