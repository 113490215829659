/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import fetchWrapper from './fetchWrapper';
import { PROFESSIONALS_API } from '../config/ServiceConstants';
import { User as UserAuth } from 'firebase/auth';
import { ProfessionalsQuery } from '../models/ProfessionalsQuery';

export function searchProfessionals(
  userAuth: UserAuth,
  query: ProfessionalsQuery
): Promise<any> {
  const url = `${PROFESSIONALS_API}/search`;
  return fetchWrapper(userAuth).post(url, query);
}

export function getAllProfessionals(
  userAuth: UserAuth
): Promise<any> {
  const url = `${PROFESSIONALS_API}`;
  return fetchWrapper(userAuth).get(url);
}

export function getProfessional(
  userAuth: UserAuth,
  userid: string
): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userid}/professionaldetails`;
  return fetchWrapper(userAuth).get(url);
}

export function fetchProfessionalDetails(
  userAuth: UserAuth,
  userId: string
): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/professionaldetails`;
  return fetchWrapper(userAuth).get(url);
}

export function patchIsFeatured(
  userAuth: UserAuth,
  userId: string,
  isFeatured: number
): Promise<any> {
  const url = `${PROFESSIONALS_API}/${userId}/isfeatured`;
  // const body = isFeatured ? 1 : 0;
  console.log('isFeatured: ', isFeatured);
  // const body = { "isFeatured": isFeatured };
  return fetchWrapper(userAuth).put(url, isFeatured);
}