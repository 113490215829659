export interface OutfitQuery {
    /**
     * A string that identifies the User making a WardrobeItemQuery.
     */
    userid?: string;
    /**
     * sort field
     */
    sf?: string;
    /**
     * sort direction (ASC or DESC)
     */
    sd?: string;
    /**
     * page size
     */
    ps?: number;
    /**
     * page offset
     */
    po?: number;
    /**
     * userid of the user that created the outfit (only if different from the owner)
     */
    createdby?: string;
    /**
     * value to search for
     */
    searchterm?: string;
    ownership?: string;
    tags?: string[];
    ispublished?: boolean;
    isproforclient?: boolean;
    iscreatedbypro?: boolean;  // whether it was created by any pro
  }
  
  export const DefaultOutfitQuery = {
    po: 1,
    ps: 40,
    sf: 'createddate',
    sd: 'desc',
    userid: undefined,
    searchterm: undefined,
    ispublished: false,
    isproforclient: true,
    iscreatedbypro: true,
  };
  