import moment from 'moment';
import { Text, Input, HStack, Flex, Select, Button, Highlight, Spinner, useDisclosure, Center, Image, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Box } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { User as UserAuth } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from '../models/User';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import LoadingScreen from '../components/LoadingScreen';
import { WardrobeItem } from '../models/WardrobeItem';
import { fetchWardrobeItemToReclassify, updateWardrobeItem } from '../services/WardrobeItemsService';
import { STYLES_LIST } from '../models/StylesList';
import { fetchPendingApplications, putApproveApplication, putDeclineApplication } from '../services/ApplicationsService';
import { Application } from '../models/Application';
import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { DataTable } from '../components/DataTable';
import LabelValueDisplayItem from '../components/LabelValueDisplayItem';

function ApplicationsScreen() {
  const { currentUser } = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [applications, setApplications] = useState<Application[] | undefined>();
  const [selectedApplication, setSelectedApplication] = useState<Application | undefined>();

  const fetch = async () => {
    if (!user) return;
    await fetchPendingApplications(user)
      .then((res: { json: () => any }) => res.json())
      .then((result) => setApplications(result));
  };

  useEffect(() => {
    if (!user) return;
    console.log('Reclassify.useEffoect');
    fetch().catch(console.error);
  }, [user]);

  const handleApproveApplication = async () => {
    await putApproveApplication(user as UserAuth, selectedApplication?.id as number).then(() => onClose());
  }

  const handleDeclineApplication = async () => {
    await putDeclineApplication(user as UserAuth, selectedApplication?.id as number).then(() => onClose()); 
  }

  const handleSkipApplication = () => {
    onClose();
  }

  type applicationTableDisplayData = {
    name: string;
    date: Date;
    yearsExperience: number;
    location: string;
    id: number;
  };

  const tabledata: applicationTableDisplayData[] = !applications ? [] : applications.map((a: Application) => ({
      name: `${a.applicant.firstname} ${a.applicant.lastname}`,
      date: a.timestamp,
      yearsExperience: a.experience.yearsexperience as number,
      location: a.applicant.city as string,
      id: a.id as number,
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedtabledata = useMemo(() => tabledata, [applications]);
  const columnHelper = createColumnHelper<applicationTableDisplayData>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info: any) => info.getValue(),
      header: 'Name',
    }),
    columnHelper.accessor('date', {
      cell: (info: any) => moment(info.getValue()).format('MMM DD'),
      header: 'Date Submitted',
      meta: {
        isDate: true,
      },
    }),
    columnHelper.accessor('yearsExperience', {
      cell: (info: any) => info.getValue(),
      header: 'Years Experience',
      meta: {
        isNumeric: true,
      },
    }),
    columnHelper.accessor('location', {
      cell: (info: any) => info.getValue(),
      header: 'Location',
    }),
  ];

  const rowSelectedHandler = (index: number) => {
    if (!applications) return;
    const appid = tabledata[index].id;
    const app = applications.filter((e: Application) => e.id === appid)[0];
    setSelectedApplication(app);
    onOpen();
  };

  const renderApplicationSummary = () => {
    const clone = structuredClone(selectedApplication);
    // console.log(clone);
    if (!clone) return <Box />;
    const locationDisplay =
      clone.doesInPerson && clone.doesVirtual
        ? 'Virtual & In Person'
        : clone.doesInPerson && !clone.doesVirtual
        ? 'In Person Only'
        : !clone.doesInPerson && clone.doesVirtual
        ? 'Virtual Only'
        : 'None.';
    return (
      <Flex flexDir="column" flex={1} alignItems="center" justifyContent="flex-start">
        <Flex justifyContent="center" my={4}>
          <Text fontWeight="bold" letterSpacing={0.8} fontSize={['3xl', '3xl', '3xl', '4xl', '4xl']} mx={4} fontFamily="BeniBold" color="orange.400">
            Review Application
          </Text>
        </Flex>
        <Flex flex={1} flexWrap={['wrap', 'wrap', 'nowrap']}>
          <Flex flex={1} flexDir="column" borderWidth={1} p={8} mr={2} mt={[2, 0]} flexWrap={['wrap', 'wrap', 'nowrap']}>
            <Flex mb={4} justifyContent="space-between" alignItems="center" flexWrap={['wrap', 'wrap', 'nowrap']}>
              <Text fontWeight="bold" fontSize="2xl">
                About You
              </Text>
            </Flex>
            <Flex mb={4} flexWrap={['wrap', 'wrap', 'nowrap']}>
              <LabelValueDisplayItem fieldname="First Name" fieldvalue={clone.applicant?.firstname} vertical />
              <LabelValueDisplayItem fieldname="Last Name" fieldvalue={clone.applicant?.lastname} vertical />
            </Flex>
            <Flex mb={4} flexWrap={['wrap', 'wrap', 'nowrap']}>
              <LabelValueDisplayItem fieldname="Email" fieldvalue={clone.applicant?.email} vertical />
              <LabelValueDisplayItem fieldname="Phone" fieldvalue={clone.applicant?.phone} vertical />
            </Flex>
            <Flex mb={4} flexWrap={['wrap', 'wrap', 'nowrap']}>
              <LabelValueDisplayItem fieldname="City" fieldvalue={clone.applicant?.city} vertical />
              <LabelValueDisplayItem fieldname="State" fieldvalue={clone.applicant?.state} vertical />
              <LabelValueDisplayItem fieldname="Zip" fieldvalue={clone.applicant?.zipcode} vertical />
            </Flex>
            <Flex flexDir="column">
              <Text color="orange.400" fontWeight={500}>
                Social:
              </Text>
              <LabelValueDisplayItem fieldname={clone.applicant?.social?.platform as string} fieldvalue={clone.applicant?.social?.url} />
            </Flex>
          </Flex>
          <Flex flex={1} flexDir="column" mt={[2, 0]}>
            <Flex flex={1} flexDir="column" borderWidth={1} p={8} mb={2}>
              <Flex mb={4} justifyContent="space-between" alignItems="center">
                <Text fontWeight="bold" fontSize="2xl">
                  Services
                </Text>
              </Flex>
              <Flex mb={4}>
                <LabelValueDisplayItem fieldname="Which serivces will you provide?" fieldvalue={clone.services?.join(', ')} vertical />
              </Flex>
              <Flex mb={1}>
                <LabelValueDisplayItem fieldname="Do you offer in person and virtual?" fieldvalue={locationDisplay} vertical />
              </Flex>
            </Flex>
            <Flex flex={1} flexDir="column" borderWidth={1} p={8}>
              <Flex mb={4} justifyContent="space-between" alignItems="center">
                <Text fontWeight="bold" fontSize="2xl">
                  Experience
                </Text>
              </Flex>
              <Flex mb={4}>
                <LabelValueDisplayItem fieldname="How many years experience do you have?" fieldvalue={clone.experience?.yearsexperience} vertical />
              </Flex>
              <Flex mb={4}>
                <LabelValueDisplayItem
                  fieldname="How are you currently finding and engaging clients?"
                  fieldvalue={clone.experience?.currentpractice}
                  vertical
                />
              </Flex>
              <LabelValueDisplayItem fieldname="Website or Portfolio link" fieldvalue={clone.experience?.websitelink} vertical />
            </Flex>
          </Flex>
        </Flex>

      </Flex>
    );
  };

  const renderDrawerFooter = () => {
    return (
      <Flex justifyContent="space-between" my={6} width="80%" mt={[2, 0]}>        
        <Button variant="solid" onClick={handleDeclineApplication}>
          Decline
        </Button>
        <Button variant="solid" onClick={handleSkipApplication}>
          Skip
        </Button>
        <Button variant="solid" onClick={handleApproveApplication}>
          Approve
        </Button>
      </Flex>
    )
  }


  if (!user) return <LoadingScreen />;

  return (
    <Flex flexDirection="column" height="full" width="100%" flex={1} px={12} py={8} alignItems="center">
        <Text variant="header">Review Applications</Text>
        <DataTable columns={columns} data={memoizedtabledata} shadeAlternatingRows={false} handleRowSelected={rowSelectedHandler} />
        <Drawer placement="right" size="xl" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent bg="white">
            <DrawerHeader borderBottomWidth="1px">
              <Text variant="header" color="black">
                Application Details
              </Text>
            </DrawerHeader>
            <DrawerBody mt={2}>{renderApplicationSummary()}</DrawerBody>
            <DrawerFooter>{renderDrawerFooter()}</DrawerFooter>
          </DrawerContent>
        </Drawer>
    </Flex>
  );
}

export default ApplicationsScreen;
