import React from 'react';
import { List, ListItem, ListIcon, OrderedList, UnorderedList, Box, Flex } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { usePagination, DOTS } from '../hooks/usePagination';

function Pagination(props: { onPageChange: any; totalCount: any; siblingCount?: 1 | undefined; currentPage: any; pageSize: any }) {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (!paginationRange) return null;

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <UnorderedList display="flex" listStyleType="none">
      <ListItem
        key="-1"
        px={2}
        h={12}
        textAlign="center"
        mx={2}
        display="flex"
        boxSizing="border-box"
        alignItems="center"
        borderRadius="sm"
        minW="2rem"
        pointerEvents={currentPage === 1 ? 'none' : 'all'}
        onClick={onPrevious}
      >
        <ChevronLeftIcon />
      </ListItem>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <ListItem key={pageNumber} px={2} h={12} textAlign="center" mx={2} display="flex" boxSizing="border-box" alignItems="center" borderRadius="sm" minW="2rem">
              &#8230;
            </ListItem>
          );
        }

        return (
          <ListItem
            key={pageNumber}
            px={2}
            h={12}
            textAlign="center"
            mx={2}
            display="flex"
            boxSizing="border-box"
            alignItems="center"
            borderRadius="sm"
            minW="2rem"
            bg={pageNumber === currentPage ? 'grey.100' : 'white'}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </ListItem>
        );
      })}
      <ListItem
        px={2}
        h={12}
        textAlign="center"
        mx={2}
        display="flex"
        boxSizing="border-box"
        alignItems="center"
        borderRadius="sm"
        minW="2rem"
        pointerEvents={currentPage === lastPage ? 'none' : 'all'}
        onClick={onNext}
      >
        <ChevronRightIcon />
      </ListItem>
    </UnorderedList>
  );
}

export default Pagination;
