import { User as UserAuth } from 'firebase/auth';
import moment from 'moment';
import fetchWrapper from './fetchWrapper';
import { USER_ADMIN_API, WARDROBE_ADMIN_API } from '../config/ServiceConstants';

export function fetchUserServiceDashboard(userAuth: UserAuth): Promise<any> {
    const url = `${USER_ADMIN_API}/dashboard`;
    return fetchWrapper(userAuth).get(url);
}

export function fetchWardrobeServiceDashboard(userAuth: UserAuth): Promise<any> {
    const url = `${WARDROBE_ADMIN_API}/dashboard`;
    return fetchWrapper(userAuth).get(url);
}