export const STYLES_LIST = [
  'artsy',
  'chave',
  'chic',
  'conservative',
  'country',
  'edgy',
  'elegant',
  'exotic',
  'flamboyant',
  'gansta',
  'Garconne',
  'geeky',
  'girly',
  'goth',
  'grunge',
  'haute couture',
  'hiphop',
  'hippy',
  'hipster',
  'kawai',
  'preppy',
  'punk',
  'rave',
  'rocker',
  'sexy',
  'skater',
  'sporty',
  'stoner',
  'tacky',
  'tomboy',
  'trendy',
  'vintage',
];
