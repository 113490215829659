import { Box, Button, Divider, Flex, HStack, Image, Input, SimpleGrid, Spinner, Switch, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { FeaturedContentTopicItem, FeaturedContentTopic, FeaturedContentTopicItemsQuery, DefaultFeaturedContentTopicItemsQuery } from '../models/Featured';
import { OUTFIT_DISPLAY_HEIGHT } from '../config/ServiceConstants';
import OutfitPartsDisplay from './OutfitPartsDisplay';
import { PaginatedResult } from '../models/PaginatedResult';
import { searchAllOutfits } from '../services/OutfitsService';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../config/firebase';
import { CurrentUserContext } from '../contexts/CurrentUserProvider';
import { Outfit } from '../models/Outfit';
import { DefaultOutfitQuery, OutfitQuery } from '../models/OutfitQuery';
import { SmallAddIcon, SmallCloseIcon } from '@chakra-ui/icons';
import FeaturedContentTopicSelectableImage from './FeaturedContentTopicSelectableImage';
import { createFeaturedContentTopic, fetchFeaturedContentTopicTags, searchFeaturedContentTopicItems, updateFeaturedContentTopic } from '../services/FeaturedContentService';
import Pagination from './Pagination';
import { MultiSelect } from 'chakra-multiselect';
import loggerService from '../services/LoggerService';

type FeaturedContentTopicDetailsProps = {
    topic: FeaturedContentTopic;
    handleClose: () => void;
};

function FeaturedContentTopicDetails({ topic, handleClose }: FeaturedContentTopicDetailsProps): React.ReactElement {

    const { currentUser } = useContext(CurrentUserContext);
    const [user] = useAuthState(auth);
    const [featuredTopic, setFeaturedTopic] = useState<FeaturedContentTopic>(topic);
    const [selectedTopicTags, setSelectedTopicTags] = useState<any[]>(topic.hashtags ? topic.hashtags.map((lbl) => ({ label: lbl, value: lbl })) : []);
    const [featuredContentTopicTags, setFeaturedContentTopicTags] = useState<string[]>();
    const [outfitQuery, setOutfitQuery] = useState<OutfitQuery>({ ...DefaultOutfitQuery });
    const [outfits, setOutfits] = useState<Outfit[] | undefined>();
    const [totalOutfitsAvailable, setTotalOutfitsAvailable] = useState<number>(0);
    const [featuredContentTopicItemsQuery, setFeaturedContentTopicItemsQuery] = useState<FeaturedContentTopicItemsQuery>({ ...DefaultFeaturedContentTopicItemsQuery });
    const [featuredContentTopicItems, setFeaturedContentTopicItems] = useState<FeaturedContentTopicItem[] | undefined>();
    const [totalFeaturedContentTopicItemsAvailable, setTotalFeaturedContentTopicItemsAvailable] = useState<number>(0);
    const [showSpinner, setShowSpinner] = useState(false);


    const fetchOutfits = async (page?: number) => {
        if (!user) return;
        setShowSpinner(true);
        if (page) outfitQuery.po = page;
        console.log('fetchOutfits query.page: ', outfitQuery.po);
        await searchAllOutfits(user, outfitQuery)
          .then((res) => res.json())
          .then((result: PaginatedResult) => {
            setOutfits(result.pageItems);
            setTotalOutfitsAvailable(result.totalItemsAvailable);
            setShowSpinner(false);
          });
      };

    const fetchFeaturedContentTopicItems = async (page?: number) => {
        if (!user) return;
        setShowSpinner(true);
        if (page) featuredContentTopicItemsQuery.po = page;
        console.log('query: ', featuredContentTopicItemsQuery);
        await searchFeaturedContentTopicItems(user, featuredContentTopicItemsQuery)
            .then((res) => res.json())
            .then((result: PaginatedResult) => {
                setFeaturedContentTopicItems(result.pageItems);
                setTotalFeaturedContentTopicItemsAvailable(result.totalItemsAvailable);
            })
            .catch((err) => console.log(err))
            .finally(() => setShowSpinner(false));
    }

    useEffect(() => {
        if (!user) return;
        if (featuredContentTopicTags && featuredContentTopicTags.length > 0) return;
        const fetch = async () => {
            await fetchFeaturedContentTopicTags(user)
            .then((res) => res.json())
            .then((result: string[]) => {
                if (!result) result = [];
                setFeaturedContentTopicTags(result);
            });
        };
        fetch().catch(console.error);
    }, [user]);

    const handleAddOutfitToTopic = async (outfit: Outfit) => {
        const clone = structuredClone(featuredTopic);
        const newid = !clone.contentItems || clone.contentItems.length === 0 ? 1 : Math.max(...clone.contentItems.map((o: FeaturedContentTopicItem) => o.id)) + 1;
        const newitem: FeaturedContentTopicItem = {
            id: newid,
            status: 'draft',
            contenttype: 'outfit',
            title: outfit.name ? outfit.name : 'outfit',
            outfit: outfit,
            createdbyuserid: currentUser.userid,
            createdbyname: currentUser.username,
            createddate: new Date(),
        };
        if (!clone.contentItems) clone.contentItems = [];
        clone.contentItems.push(newitem);
        setFeaturedTopic(clone);
    }

    const handleRemoveOutfitFromTopic = async (outfit: Outfit) => {
        const clone = structuredClone(featuredTopic);
        clone.contentItems = clone.contentItems.filter((ci: FeaturedContentTopicItem) => ci.contenttype !== 'outfit' || ci.outfit?.id !== outfit.id);
        setFeaturedTopic(clone);
    }

    const handleAddTopicItemToTopic = async (item: FeaturedContentTopicItem) => {
        const clone = structuredClone(featuredTopic);
        // const newid = !clone.contentItems || clone.contentItems.length === 0 ? 1 : Math.max(...clone.contentItems.map((o: FeaturedContentTopicItem) => o.id)) + 1;
        // item.id = newid;
        if (!clone.contentItems) clone.contentItems = [];
        clone.contentItems.push(item);
        if (!clone.contentItemIds) clone.contentItemIds = [];
        // clone.contentItemIds.push(newid);
        clone.contentItemIds.push(item.id);
        console.log('clone: ', clone);
        setFeaturedTopic(clone);
    }

    const handleRemoveTopicItemFromTopic = async (item: FeaturedContentTopicItem) => {
        const clone = structuredClone(featuredTopic);
        clone.contentItems = clone.contentItems.filter((ci: FeaturedContentTopicItem) => ci.id !== item.id);
        setFeaturedTopic(clone);
    }

    const handleRemoveImageFromTopic = async (url: string) => {
        const clone = structuredClone(featuredTopic);
        clone.contentItems = clone.contentItems.filter((ci: FeaturedContentTopicItem) => ci.contenttype !== 'image' || ci.imageurl !== url);
        setFeaturedTopic(clone);
    }

    const handleSaveFeaturedContentTopic = async () => {
        if (!featuredTopic) return;
        if (!user) return;
        setShowSpinner(true);
        if (selectedTopicTags)
            featuredTopic.hashtags = selectedTopicTags.map((s) => s.value);
        console.log('saving: ', featuredTopic);
        if (featuredTopic.id && featuredTopic.id > 0) {
            // update
            await updateFeaturedContentTopic(user, featuredTopic)
                .then((res) => console.log(res))
                .catch((err) => console.log(err))
                .finally(() => setShowSpinner(false));
        } else {
            // create new
            await createFeaturedContentTopic(user, featuredTopic)
                .then((res) => {
                    console.log('res: ', res);
                    return res.json();
                })
                .then((result: FeaturedContentTopic) => {
                    console.log(result);
                    setFeaturedTopic(result);
                })
                .catch((err) => console.log(err))
                .finally(() => setShowSpinner(false));
        }
    }

    if (showSpinner) return <Spinner />;

    return (
        <Flex height="full" width="100%" flexDir="row" flex={1} alignItems="flex-start" justifyContent="flex-start" mb={48}>
            <Flex flex={1} flexDirection="column" overflowY="scroll" px={8} mt={4} borderRightWidth={1}>
                <Flex justifyContent="center" alignItems="center" mb={4} flexDirection="column">
                    <HStack>
                        <Button width={32} mr={4} onClick={handleClose}>Go Back</Button>
                        <Text fontWeight="bold" letterSpacing={0.8} fontSize={['3xl', '3xl', '3xl', '4xl', '4xl']} fontFamily="BeniBold" color="orange.400">
                            Featured Topic
                        </Text>
                        <Button ml={4} onClick={() => handleSaveFeaturedContentTopic()}>Save</Button>
                    </HStack>
                    <Input type="text" m={2} value={featuredTopic.title} onChange={(e) => setFeaturedTopic({...featuredTopic, title: e.target.value})} />
                    <Input type="text" m={2} value={featuredTopic.description} onChange={(e) => setFeaturedTopic({...featuredTopic, description: e.target.value})} />
                    <Flex m={2} px={1} flex={1} width="100%" justifyContent="space-between" mb={4} borderBottomWidth={0.5} pb={2}>
                        <Text>Is Active?</Text>
                        <Switch
                            isChecked={featuredTopic.isActive}
                            onChange={() =>
                                setFeaturedTopic({
                                    ...featuredTopic,
                                    isActive: !featuredTopic?.isActive,
                                } as FeaturedContentTopic)
                            }
                        />
                    </Flex>
                    <FeaturedContentTopicSelectableImage url={featuredTopic.coverImageUrl} handleImageChanged={(newurl: string) => setFeaturedTopic({...featuredTopic, coverImageUrl: newurl})} />
                    <HStack>
                        <Text>Tags:</Text>
                        <MultiSelect
                            create
                            multiple
                            options={[]}
                            // value={featuredTopic?.hashtags || undefined}
                            value={selectedTopicTags}
                            onChange={(e) => {
                                // const clone: FeaturedContentTopic = structuredClone(featuredTopic);
                                // clone.hashtags = e as unknown as string[];
                                // setFeaturedTopic(clone);
                                setSelectedTopicTags(e as unknown as string[]);
                            }}
                        />
                    </HStack>
                </Flex>
                <Divider />
                <Text>Featured Content Items:</Text>
                <SimpleGrid columns={2} spacing={8} flex={1} mt={4} mx={12}>
                    {featuredTopic.contentItems && featuredTopic.contentItems.map((fci: FeaturedContentTopicItem) => {
                        if (fci.contenttype === 'collage') {
                            return (
                                <Flex key={fci.id.toString()} flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                    <SmallCloseIcon
                                        color="black"
                                        boxSize={4}
                                        onClick={() => handleRemoveTopicItemFromTopic(fci)}
                                    />
                                    <Image h={40} w={40} src={fci.collageimageurl} />
                                </Flex>
                            )
                        }
                        if (fci.contenttype === 'outfit' && fci.outfit) {
                            return (
                                <Flex key={fci.id} flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                    <SmallCloseIcon
                                        position="absolute"
                                        zIndex={11}
                                        transform="translateY(24px) translateX(0px)"
                                        color="black"
                                        boxSize={4}
                                        onClick={() => handleRemoveOutfitFromTopic(fci.outfit as Outfit)}
                                    />
                                    <Box borderWidth={1}>
                                        <OutfitPartsDisplay outfit={fci.outfit} height={OUTFIT_DISPLAY_HEIGHT * 0.5} width={OUTFIT_DISPLAY_HEIGHT * 0.5} resizeRatio={0.5} />
                                    </Box>
                                </Flex>
                            )
                        }
                        if (fci.contenttype === 'image' && fci.imageurl) {
                            return (
                                <Box key={fci.id}>
                                    <SmallCloseIcon
                                        position="absolute"
                                        zIndex={11}
                                        transform="translateY(-120px) translateX(-40px)"
                                        color="black"
                                        boxSize={4}
                                        onClick={() => handleRemoveImageFromTopic(fci.imageurl as string)}
                                    />
                                    <Image key={fci.id} fit="contain" height={OUTFIT_DISPLAY_HEIGHT * 0.5} src={fci.imageurl} />
                                </Box>
                            )
                        }
                    })}
                </SimpleGrid>
            </Flex>

            <Flex flex={2} flexDirection="column" px={8} mt={4}>
                <Flex flex={1} flexDirection="column" borderWidth={1} my={4} p={2} alignItems="center">
                    <HStack my={1}>
                        <Text>Hashtag (case sensitive):</Text>
                        <Input type="text" m={2} value={featuredContentTopicItemsQuery.tags?.join(', ')} onChange={(e) => {
                            const filtervalue = e.target.value ? e.target.value.split(', ') : undefined;
                            setFeaturedContentTopicItemsQuery({...featuredContentTopicItemsQuery, tags: filtervalue})}
                            } />
                    </HStack>
                    <HStack my={1}>
                        <Text>Created by username</Text>
                        <Input type="text" m={2} value={featuredContentTopicItemsQuery.createdbyusername} onChange={(e) => setFeaturedContentTopicItemsQuery({...featuredContentTopicItemsQuery, createdbyusername: e.target.value})} />
                    </HStack>
                    <HStack my={1}>
                        <Text>Status</Text>
                        <Input type="text" m={2} value={featuredContentTopicItemsQuery.status} onChange={(e) => setFeaturedContentTopicItemsQuery({...featuredContentTopicItemsQuery, status: e.target.value})} />
                    </HStack>
                    <Button mt={2} width="35%" onClick={() => fetchFeaturedContentTopicItems()}>Search Items</Button>
                </Flex>
                {/* <Flex flex={1} flexDirection="row" flexWrap="wrap" p={2}>
                    {outfits && outfits.map((outfit) => {
                        return (
                            <Flex flexDirection="column" mb={2}>
                                <Box key={outfit.id} p={4} m={2} borderWidth={0.5}>
                                    <SmallAddIcon
                                        position="absolute"
                                        zIndex={11}
                                        transform="translateY(-10px) translateX(-10px)"
                                        color="black"
                                        boxSize={6}
                                        onClick={() => handleAddOutfitToTopic(outfit)} />
                                    <OutfitPartsDisplay outfit={outfit} height={OUTFIT_DISPLAY_HEIGHT * 0.5} width={OUTFIT_DISPLAY_HEIGHT * 0.5} resizeRatio={0.5} />
                                </Box>
                                <Text>{outfit.id}</Text>
                                <Text fontSize="xs">{outfit.createdby ? outfit.createdby : outfit.userid}</Text>
                                <Text fontSize="xs">{outfit.height}</Text>
                            </Flex>
                        )
                    })}
                </Flex> */}
                <SimpleGrid columns={2} spacing={4} flex={1} mt={4}>
                    {featuredContentTopicItems && featuredContentTopicItems.map((item) => {
                        return (
                          <Flex
                            key={item.id.toString()}
                            flexDirection="column"
                            alignItems="center"
                            borderWidth={0.5}
                            borderColor="grey.200"
                            borderRadius="md"
                            px={4}
                            py={2}
                            m={2}
                            minW={44}
                          >
                            <SmallAddIcon
                                // position="absolute"
                                zIndex={14}
                                // transform="translateY(-10px) translateX(-10px)"
                                alignSelf="flex-end"
                                color="black"
                                boxSize={6}
                                onClick={() => handleAddTopicItemToTopic(item)} />
                            <Text align="center" fontWeight="bold">
                              {item.createdbyname}
                            </Text>
                            <Text>
                              {item.status}
                            </Text>
                            {item.collageimageurl && <Image src={item.collageimageurl} h={48} w={48} />}
                            {!item.collageimageurl && <Box height={32} width={32} borderWidth={1} />}
                            <Text mt={2}>{item.hashtags?.join(' • ').substring(0, 30)}</Text>
                            <Flex flex={1} width="100%" flexDir="row" flexWrap="wrap" justifyContent="flex-start" alignItems="flex-start" borderWidth={0.5}>
                                {item.collageItems && item.collageItems.map((ci) => {
                                    return (
                                        <Box key={ci.id} mx={2}>
                                            <Image src={ci.imageurl} h={16} w={16} />
                                        </Box>
                                    )
                                })
                                }
                            </Flex>
                          </Flex>
                        )
                    })
                    }
                </SimpleGrid>
                <Flex flex={1} borderWidth={1}>
                    <Pagination
                        currentPage={featuredContentTopicItemsQuery.po}
                        totalCount={totalFeaturedContentTopicItemsAvailable}
                        pageSize={featuredContentTopicItemsQuery.ps}
                        onPageChange={(page: any) => {
                            setFeaturedContentTopicItemsQuery({ ...featuredContentTopicItemsQuery, po: page });
                            fetchFeaturedContentTopicItems(page);
                        }}
                    />
                </Flex>
            </Flex>
            <Box h={48} />
      </Flex>
    )
}

export default FeaturedContentTopicDetails;
